<script>

import Layout from '../layouts/main.vue';
import pageHeaderVue from '../../components/page-header.vue';
import TrainInfoVue from '../rail/TrainInfo.vue';
import TravelerInfoVue from '../booking/TravelerInfo.vue';
import bookingUtils from '../../utils/booking/bookingUtils';
import { mapGetters, mapActions } from 'vuex';
import bookingApi from '../../api/bookingApi';
import RailAPI from '../../api/RailAPI';

export default {
    name: '',
    components: {
        Layout,
        pageHeaderVue,
        TrainInfoVue,
        TravelerInfoVue,
    },
    data () {
        return {
            title: "Reservation",
            items: [
                {
                    // #TODO
                    text: "Booking",
                },
                {
                    text: "Reservation Details",
                    active: true,
                },
            ],  
            isBookingNotFound: false,
            isOverLayShow: false,
            isEmpty: false,
            editable: false,
            isDisplayed: true,
            isRefundModalVisible: false,
            isTicketingOptionHelperVisible: false,
            confirmRefundBtnDisabled: false,
            isRefundAgreed: false,
            priceDetails: {
                adult1: 300,
                adult2: 300,
                child1: 100,
                total: 700
            },
            travelers: [
                {passenger: 'YIM/KIN LONE MR', dateOfBirth: '1990-12-24', telephoneNumber: '+852 31806130', email: 'yimlone@jebsentravel.com'},
                {passenger: 'CHEN/MEI MEI MISS', dateOfBirth: '2010-10-04', telephoneNumber: null, email: null},
                {passenger: 'ZHANG/XIAO XIAO MRS', dateOfBirth: '1970-05-11', telephoneNumber: null, email: null}
            ],
            alertSettings: {
                dismissCountDown: 0,
                countDownSecs: 4,
                title: '',
                alertMsg: '',
                alertVariant: 'warning'
            },
            bookingId: {},
            railEuropeBookingId: {},
            searchDetailsRQ: {},
            isRefunding: false,
            refundReservation: {},
            refundRQ: {},
            refundCommitRQ: {},
            refundCurrency: '',
            refundAmount: 0,
            sellingRefundCurrency: '',
            sellingRefundAmount: 0,
            reservations: [],
            providerBookingStatus: '',
            sellingCurrencyCode: '',
            bookingTotalPrice: 0,
            bookingTotalTaxes: [],
            isIssueTicketConfirmModalVisible: false,
            isIssueTicketAgreed: false,
            checkOutBookingId: '',
            checkOutRailEuropeBookingId: '',
            checkOutPrice: '',
        }
    },
    computed: {
        ...mapGetters("booking",[
            "bookingHistoryRS",
            "bookingDetailsRS",
            "selectedBooking",
            "cancelRS"
        ]),
    },
    created() {
        this.isEmpty = true
        this.bookingId = this.$route.query.bookingId
        this.railEuropeBookingId = this.$route.query.railEuropeBookingId
        this.initiateBooking()
    },
    watch:{
        // 从api获取数据成功，检测到bookingHistoryRS变化，执行数据加载
        bookingHistoryRS: function() {
            // this.$nextTick(function(){
            //     if(this.bookingHistoryRS.success){
            //         let selectedBooking = bookingUtils.getRailReservationDetails(this.bookingHistoryRS)
            //         this.selectedBooking = selectedBooking
            //         this.updateSelectedBooking({
            //             selectedBooking: selectedBooking
            //         })  

            //         if(this.bookingHistoryRS.totalElements > 0){
            //             this.refundRQ = bookingUtils.getRefundRQ(this.bookingHistoryRS.elements[0])
            //         }
                    
            //     }else{
            //         this.alertSettings.alertMsg = 'Status and some datas loaded unsuccessfully'
            //         this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs
            //     }
                
            // })
        },
        bookingDetailsRS(){
            this.$nextTick(function(){
                if(this.bookingDetailsRS.success){
                    this.isEmpty =false;
					let reservationsTem = JSON.stringify(this.bookingDetailsRS.railReservations)
                    this.reservations = JSON.parse(reservationsTem)
                    this.reservations.forEach(revItem => {

                        if (revItem.uniqueID && revItem.uniqueID.length>0){
                            let erpBookingStatus = '';
                            revItem.uniqueID.forEach(uniqueId => {
                                if (uniqueId.type =='BOOKING_STATUS' && uniqueId.id_context == 'ERP'){
                                    erpBookingStatus = uniqueId.id;
                                }
                            })
                            revItem.erpBookingStatus = erpBookingStatus;
                        }

                        if(revItem.tpaExtensions.valueMap.reservationType == 'P2P'){
                            revItem.type = "P2P"
                            revItem.bookingDetails = bookingUtils.getBookingDetailsResult(revItem)
                        }
                        if(revItem.tpaExtensions.valueMap.reservationType == 'PASS'){
                            revItem.type = "PASS"
                            revItem.bookingDetails = bookingUtils.getBookingDetailsResult(revItem)
                        }
                        
                        if(this.providerBookingStatus == ''){
                            this.providerBookingStatus = revItem.tpaExtensions.valueMap.providerBookingStatus
                        }

                        this.bookingTotalPrice = (Number(this.bookingTotalPrice) + Number(revItem.bookingDetails.price.sellingTotal)).toFixed(2)
                        if(this.sellingCurrencyCode == ''){
                            this.sellingCurrencyCode = revItem.bookingDetails.price.sellingCurrencyCode
                        }
                        
                        revItem.bookingDetails.price.taxes.forEach(taxItem => {
                            let hasIndex = this.bookingTotalTaxes.findIndex(item => {
                                return item.name === taxItem.name
                            })
                            if(hasIndex == -1){
                                this.bookingTotalTaxes.push({'sellingAmount': taxItem.sellingAmount, 'sellingCurrencyCode': taxItem.sellingCurrencyCode, 'name': taxItem.name})
                            }else{
                                this.bookingTotalTaxes[hasIndex].sellingAmount = (Number(this.bookingTotalTaxes[hasIndex].sellingAmount)+Number(taxItem.sellingAmount)).toFixed(2)
                            }

                        })
                        
                    })
                }else{
                    this.isBookingNotFound = true
                    this.setAlertMessage('danger', this.bookingDetailsRS.errorTitle, this.bookingDetailsRS.errorTitle, 15)
                }
            })    
        }
    },
    methods: {
        ...mapActions("booking", [
            "getBookingHistoryRS",
            "getBookingDetails",
            'updateSelectedBooking',
            'updateBookingDetails',
        
        ]),
        countDownChanged(dismissCountDown) {
            this.alertSettings.dismissCountDown = dismissCountDown
        },
        setAlertMessage(type, title, message, countDown){
            this.alertSettings.title = title
            this.alertSettings.alertMsg = message
            this.alertSettings.alertVariant = type
            this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs + countDown
        },
        initiateBooking(){
            // console.log("railEuropeBookingId id" + this.railEuropeBookingId)
            // let searchRQ = bookingUtils.getRailReservationsRQ(null, this.bookingId, 0, 1);
            // this.searchDetailsRQ = {"uniqueID": {"id": this.railEuropeBookingId}}
            this.searchDetailsRQ = {"uniqueID": {
                "id": this.bookingId,
                "type": "BOOKING_ID",
                "id_context": "ERP"
              }
            }
            // console.log(JSON.stringify(searchRQ))
            // this.getBookingHistoryRS(searchRQ)
            this.getBookingDetails({
                searchDetailsRQ: this.searchDetailsRQ
            })
        },
        refreshBookingStatus() {
            this.reservations = JSON.parse('[]')
			this.bookingTotalPrice = 0
			this.bookingTotalTaxes = JSON.parse('[]')
            this.providerBookingStatus = ''
            this.isEmpty = true
            this.getBookingDetails({
                searchDetailsRQ: this.searchDetailsRQ
            })   
            // this.refundReservation.bookingDetails.resStatusId = 'CANCELLED'
            // this.providerBookingStatus = 'MODIFIED'
        },
        checkOut(){
            this.hideIssueTicketModal()
            this.isOverLayShow = true

            let confirmBookingRQ = {}
            confirmBookingRQ.uniqueIDs = []
            confirmBookingRQ.uniqueIDs.push({id: this.checkOutRailEuropeBookingId})

            RailAPI.confirmBooking(confirmBookingRQ).then(res => {
                this.isOverLayShow = false
                let confirmbookingRS = res.data
                if(confirmbookingRS.confirmations !== undefined && confirmbookingRS.confirmations.length > 0){
                    this.refreshBookingStatus()
                    this.setAlertMessage('success', 'Successfully!', 'Booking was confirmed. Tickets were issued.', 15)
                }else if (!confirmbookingRS.success && confirmbookingRS.errors && confirmbookingRS.errors.errors){
                    let error = confirmbookingRS.errors.errors[0];
                    let stringValue = error.stringValue;
                    this.setAlertMessage('danger', 'Sorry!', stringValue, 15)
                }else {
                    let title = "Sorry!"
                    let errorMsg = ""
                    try{
                        let errorString = confirmbookingRS.errors.errors[0].stringValue
                        title = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).label
                        errorMsg = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).details
                    }catch{
                        errorMsg =  'Supplier reponse errors.'
                    }    

                    this.setAlertMessage('danger', title, errorMsg, 15)
                }
            }).catch(error => {
                this.isOverLayShow = false
                let title = 'Sorry!'
                let errorMsg = ''
                if(error.response){
                    try{
                        errorMsg = error.response.errors.errors[0].stringValue
                    }catch{
                        errorMsg = 'Some response errors.'
                    }
                }else if(error.request){
                    console.info(error.request)
                    errorMsg = 'Some request errors.'
                }else {
                    console.info(error)
                    errorMsg = 'Some data errors.'
                }
                this.setAlertMessage('danger', title, errorMsg, 15)
            })
        },
        refundQuote(revItem){
            this.isOverLayShow = true
            this.refundReservation = revItem
            let refundQuoteRQ = bookingUtils.getRefundRQ(revItem.uniqueID,this.selectedBooking.uniqueIDs)
            bookingApi.cancelBookingApi(refundQuoteRQ).then(res => {
                this.isOverLayShow = false
                let refundQuoteRS = res.data
                if(res.data.success !== undefined && res.data.success){
                    //替换operation id，形成commit的RQ
                    this.refundCommitRQ = Object.assign({}, refundQuoteRQ)
                    this.refundCommitRQ.uniqueIDs.push.apply(this.refundCommitRQ.uniqueIDs, refundQuoteRS.uniqueIDs)
                    this.refundCommitRQ.cancelType = 'COMMIT'
                    //计算退款金额
                    this.toGetRefundData(refundQuoteRS)

                    this.isRefunding = true
                    this.isRefundModalVisible = true

                    // this.setAlertMessage('success', 'Successfully!', 'Refund amount is <span class="text-danger"><b>'+ this.sellingRefundAmount +'</b><span>', 0)
                    
                }else{
                    let title = "Sorry!"
                    let errorMsg = ""
                    try{
                        let errorString = refundQuoteRS.errors.errors[0].stringValue
                        title = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).label
                        errorMsg = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).details
                    }catch{
                        errorMsg =  'Supplier reponse errors.'
                    }    

                    this.setAlertMessage('danger', title, errorMsg, 15)
                }
            }).catch(error => {
                this.isOverLayShow = false
                let title = 'Sorry!'
                let errorMsg = ''
                if(error.response){
                    try{
                        errorMsg = error.response.errors.errors[0].stringValue
                    }catch{
                        errorMsg = 'Some response errors.'
                    }
                }else if(error.request){
                    console.info(error.request)
                    errorMsg = 'Some request errors.'
                }else {
                    console.info(error)
                    errorMsg = 'Some data errors.'
                }
                this.setAlertMessage('danger', title, errorMsg, 15)
            })
        },
        refundConfirm(){
            this.isRefundAgreed = false
            this.isRefundModalVisible = false

            this.isOverLayShow = true
            this.confirmRefundBtnDisabled = true
            // this.refundCommitRQ.cancelType = 'COMMIT'
            let refundCommitRQ = this.refundCommitRQ
            bookingApi.cancelBookingApi(refundCommitRQ).then(res => {
                this.isOverLayShow = false
                let refundCommitRS = res.data
                if(res.data.success !== undefined && res.data.success){
                    this.isRefundModalVisible = false
                    this.refundAmount = 0
                    this.refundCurrency = ''
                    this.sellingRefundAmount = 0
                    this.sellingCurrencyCode = ''
                    this.isRefunding = false
                    this.refreshBookingStatus()

                    this.setAlertMessage('success', 'Successfully!', 'Item was cancelled.', 15)
                }else{
                    let title = "Sorry!"
                    let errorMsg = ""
                    try{
                        let errorString = refundCommitRS.errors.errors[0].stringValue
                        title = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).label
                        errorMsg = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).details
                    }catch{
                        errorMsg =  'Supplier reponse errors.'
                    }    

                    this.setAlertMessage('danger', title, errorMsg, 15)
                }
                this.confirmRefundBtnDisabled = false
            }).catch(error => {
                this.confirmRefundBtnDisabled = false
                this.isOverLayShow = false
                let title = 'Sorry!'
                let errorMsg = ''
                if(error.response){
                    try{
                        errorMsg = error.response.errors.errors[0].stringValue
                    }catch{
                        errorMsg = 'Some response errors.'
                    }
                }else if(error.request){
                    console.info(error.request)
                    errorMsg = 'Some request errors.'
                }else {
                    console.info(error)
                    errorMsg = 'Some data errors.'
                }
                this.setAlertMessage('danger', title, errorMsg, 15)
            })  
        },
        toGetRefundData(refundQuoteRS){
            this.refundAmount = refundQuoteRS.cancelInfoRS.cancelRules[0].amount
            this.refundCurrency = refundQuoteRS.cancelInfoRS.cancelRules[0].currencyCode
            this.sellingRefundAmount = refundQuoteRS.cancelInfoRS.cancelRules[0].sellingAmount
            this.sellingRefundCurrency = refundQuoteRS.cancelInfoRS.cancelRules[0].sellingCurrencyCode
        },
        toOpenIssueTicketConfirmModal() {
            this.checkOutBookingId = this.selectedBooking.bookingId
            this.checkOutRailEuropeBookingId = this.railEuropeBookingId
            this.checkOutPrice = this.bookingTotalPrice
            
            this.isIssueTicketConfirmModalVisible = true
        },
        hideIssueTicketModal(){
            this.isIssueTicketConfirmModalVisible = false
            this.isIssueTicketAgreed = false
        },
        hideRefundConfirmModal(){
            this.isRefundAgreed = false
            this.isRefundModalVisible = false
            this.isRefunding = false
        },
        openTicketPdf(){
            // let route = this.$router.resolve({ path: this.bookingDetails.ticketPdfUrl });
            // window.open(route.href);
            window.open(this.bookingDetailsForP2P.ticketPdfUrl, "_blank")
        }
    }
}
</script>

<template>
    <Layout>
        <pageHeaderVue :title="title" :items="items" />
        <b-overlay :show="isOverLayShow" rounded="sm">
        <b-card>
            <b-row>
                <b-col>
                    <div class="mb-4 border bg-soft-primary d-flex">
                        <div class="p-3">
                            <span class="font-size-16 text-info">
                               <strong>JTL no. {{selectedBooking.bookingId}}</strong>
                            </span>
                        </div>
                        <div class="ml-4 p-3" v-if="isEmpty">
                            <b-spinner small></b-spinner>
                            <span class="">Loading...</span>
                        </div>
                        <!-- <div class="p-2">
                            <b-badge size="sm" :variant="selectedBooking.statusId == 'BOOKING_CANCELLED' ? 'secondary' : 'info'">{{selectedBooking.statusId}}</b-badge>
                        </div>    -->
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="d-flex">
                        <div class="p-2">
                            Supplier Ref. :
                            <span class="text-info">
                                {{selectedBooking.reference}} 
                            </span>
                        </div> 
                        <div class="p-2">
                            Booking Status :
                            <span class="text-info">
                                {{providerBookingStatus}}
                            </span>
                        </div>
                        <div class="p-2" v-if="providerBookingStatus === 'PREBOOKED'">
                            <span class="text-info">
                                <b-badge size="lg" variant="secondary">Not yet issue ticket</b-badge>
                            </span>
                        </div> 
                    </div>
                </b-col>
            </b-row>
            <!-- <b-row v-if="providerBookingStatus === 'INVOICED'">
                <b-col>
                    <div
                    class="p-2 d-flex justify-content-start"
                    >
                        <div
                        class="mr-3"
                        >
                            <b-button size="sm"  variant="success"  :disabled="isRefunding || selectedBooking.statusId === 'BOOKING_CANCELLED'"
                            @click.prevent="refundQuote"
                            >Refund</b-button>
                        </div>
                        <div
                        class="mx-3"
                        >
                            <b-button size="sm" variant="success" disabled>Exchange</b-button>
                        </div>
                        <div
                        class="mx-3"
                        >
                            <b-button size="sm" variant="success" disabled>Make Payment</b-button>
                        </div>
                        <div
                        class="mx-3"
                        >
                            <b-button size="sm" variant="success" disabled>Print Invoice</b-button>
                        </div>
                    </div>   
                </b-col>
            </b-row> -->
            <!-- <b-row v-show="isRefunding">
                <b-col>
                    <div class="bg-soft-info">
                        <div class="p-2 d-flex">
                            <div class="px-1">Refundable Amount</div>
                            <div class="px-1"><span class="text-danger"><b>{{sellingRefundCurrency}}</b></span></div>
                            <div class="px-1"><span class="text-danger"><b>{{sellingRefundAmount}}</b></span></div>
                        </div>
                        <div class="p-2 d-flex">
                            <div class="px-2">
                                <b-button variant="info"
                                @click="() => {isRefunding = false}"
                                >Cancel</b-button>
                            </div>
                            
                            <div class="px-2">
                                <b-button variant="success"
                                v-b-modal.booking-refund-modal
                                >Confirm Refund</b-button>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row> -->
            <b-row v-if="isBookingNotFound">
                <b-col>
                    <div class="p-3 border border-senondary bg-light">
                        <span class="font-size-24 text-secondary">Booking not found with given identifier</span>
                    </div>
                </b-col>
            </b-row>
            <b-row v-for="(item, index) in reservations" :key="`product-${index}`">
                <b-col v-if="item.bookingDetails.type == 'PASS'">
                    <div class="mt-3 p-3 border-top border-info">
                        <div class="border d-flex bg-light">
                            <div class="p-2">
                                Ticket Status :
                                <span class="text-info">
                                    {{item.bookingDetails.resStatusId}}
                                </span>
                            </div>
                            <div class="p-2">
                                Ticket Ref (PNR) :
                                <span class="text-info">
                                    {{item.bookingDetails.pnr}}
                                </span>
                            </div>
                            <div class="p-2">
                                Ticketing option :
                                <span class="text-info">
                                    {{item.bookingDetails.ticketOption}}
                                    <i class="dripicons-question" v-b-tooltip.hover id="ticket-option-tooltip" @click="isTicketingOptionHelperVisible = true"></i>
                                </span>
                            </div>
                            <div
                            class="ml-2 p-2" v-if="item.bookingDetails.resStatusId == 'CONFIRMED'"
                            >
                                <b-button size="sm"  variant="secondary"
                                @click.prevent="refundQuote(item)"
                                >Refund</b-button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h6>Trips</h6>
                            <b-card>
                                <span class="text-success font-size-15">
                                    <b>{{item.bookingDetails.label}}</b>
                                    <br/>{{item.bookingDetails.travelClass}}
                                    <br/>First travel at {{item.bookingDetails.firstTravelDate}}
                                    
                                </span>   
                            </b-card>
                        </div>
                        <div class="mt-3">
                            <h6>Passengers</h6>
                            <TravelerInfoVue :travelers="item.bookingDetails.passengers">
                            </TravelerInfoVue>
                        </div>
                        <div class="mt-3" >
                            <h6>Tickets</h6>
                            <div class="d-flex" v-if="item.bookingDetails.tickets.length > 0">
                                <div class="p-2 ml-2 border text-center" v-for="ticketItem, index in item.bookingDetails.tickets" :key="`ticket-download-link-${index}`">
                                    <a target="_blank"
                                    :href="ticketItem"
                                    >
                                        {{'Ticket '+(index+1)}}
                                    </a>
                                </div>
                            </div>
                            <div v-else class="p-2">
                                <span class="text-muted">No tickets have been captured</span>
                            </div>
                        </div>

                        <div class="mt-3 py-3 border-top border-bottom d-flex justify-content-end">
                            <div class="px-4" v-for="taxItem, taxIndex in item.bookingDetails.price.taxes" :key="`pass-tax-${taxIndex}`">
                                <small>{{taxItem.name}} <span class="text-danger">{{taxItem.sellingCurrencyCode + ' '+ taxItem.sellingAmount}}</span></small>
                            </div>
                            <div class="px-4 d-flex justify-content-end">
                                <div class="px-1">Total after commission</div>
                                <div class="px-1"><span class="text-danger"><b>{{item.bookingDetails.price.sellingCurrencyCode}}</b></span></div>
                                <div class="px-1"><span class="text-danger"><b>{{item.bookingDetails.price.sellingTotal}}</b></span></div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <h6>Ticket conditions</h6>
                            <b-button size="sm" v-b-toggle.pass-con-collapse-1 variant="light">Collapse / Expand<i class="mdi mdi-plus-minus"></i></b-button>
                            <b-collapse id="pass-con-collapse-1" class="mt-2 p-3">
                                <span>{{item.bookingDetails.cabinClass[0]}}</span>
                                <div 
                                class="mt-2"
                                v-for="conItem, idx in item.bookingDetails.passConditions" :key="`con-${idx}`"
                                >
                                    <p><b>{{conItem.title}}</b></p>
                                    <p v-html="conItem.stringValue"></p>
                                </div>
                            </b-collapse>
                            
                        </div>
                    </div>
                </b-col>

                <b-col v-if="item.bookingDetails.type == 'P2P'">
                    <div class="mt-3 p-3 border-top border-info">
                        <div class="border d-flex bg-light">
                            <div class="p-2">
                                Status :
                                <span class="text-info">
                                    {{item.bookingDetails.resStatusId}}
                                </span>
                            </div>
                            <div class="p-2">
                                Reference (PNR) :
                                <span class="text-info">
                                    {{item.bookingDetails.pnr}}
                                </span>
                            </div>
                            <div class="p-2">
                                Ticketing option :
                                <span class="text-info">
                                    {{item.bookingDetails.ticketOption}}
                                    <i class="dripicons-question" v-b-tooltip.hover id="ticket-option-tooltip" @click="isTicketingOptionHelperVisible = true"></i>
                                </span>
                            </div>
                            <div
                            class="ml-2 p-2" v-if="item.bookingDetails.resStatusId == 'CONFIRMED'"
                            >
                                <b-button size="sm"  variant="secondary"
                                @click.prevent="refundQuote(item)"
                                >Refund</b-button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h6>Trips</h6>
                            <div class="border-bottom d-flex justify-content-between">
                                <h5 class="font-size-14 text-success">
                                    {{item.bookingDetails.originLocation}}
                                    <i v-if="item.bookingDetails.isRoundTrip" class="mdi mdi-arrow-left-right-bold"></i>
                                    <i v-else class="mdi mdi-arrow-right-bold"></i> 
                                    {{item.bookingDetails.destinationLocation}}
                                </h5>
                            </div>
                            <TrainInfoVue v-if="item.bookingDetails.isRoundTrip"
                            :selected-outbound-ticket="item.bookingDetails.trains[0]"
                            :selected-inbound-ticket="item.bookingDetails.trains[1]"
                            ></TrainInfoVue>
                            <TrainInfoVue v-else
                            :selected-outbound-ticket="item.bookingDetails.trains[0]"
                            ></TrainInfoVue>
                        </div>
                        <div>
                            <h6>Passengers</h6>
                            <TravelerInfoVue :travelers="item.bookingDetails.passengers">
                            </TravelerInfoVue>
                        </div>
                        
                        <div class="mt-3" v-if="item.erpBookingStatus!='BOOKING_CANCELLED'">
                            <h6>Tickets</h6>
                            <div class="d-flex" v-if="item.bookingDetails.tickets.length > 0">
                                <div class="p-2 ml-2 border text-center" v-for="ticketItem, index in item.bookingDetails.tickets" :key="`ticket-download-link-${index}`">
                                    <a target="_blank"
                                    :href="ticketItem"
                                    >
                                        {{'Ticket '+(index+1)}}
                                    </a>
                                </div>
                            </div>
                            <div v-else>
                                <span>No tickets have been captured</span>
                            </div>
                        </div>
                        <div class="mt-3 py-3 border-top border-bottom d-flex justify-content-end">
                            <div class="px-4" v-for="taxItem, taxIndex in item.bookingDetails.price.taxes" :key="`p2p-tax-${taxIndex}`">
                                <small>{{taxItem.name}} <span class="text-danger">{{taxItem.sellingCurrencyCode + ' '+ taxItem.sellingAmount}}</span></small>
                            </div>
                            <div class="px-4 d-flex justify-content-end">
                                <div class="px-1">Total after commission</div>
                                <div class="px-1"><span class="text-danger"><b>{{item.bookingDetails.price.sellingCurrencyCode}}</b></span></div>
                                <div class="px-1"><span class="text-danger"><b>{{item.bookingDetails.price.sellingTotal}}</b></span></div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <h6>Ticket conditions</h6>
                            <b-button size="sm" v-b-toggle.p2p-con-collapse-1 variant="light">Collapse / Expand<i class="mdi mdi-plus-minus"></i></b-button>
                            <b-collapse id="p2p-con-collapse-1" class="mt-2 p-3">
                                <div v-if="item.bookingDetails.isRoundTrip">
                                    <div v-for="conItem,conIdx in item.bookingDetails.p2pConditions" :key="`p2p-con-${conIdx}`">
                                        <div class="p-2 border bg-light">
                                            <b>{{ conIdx == 0 ? 'Outbound' : 'Inbound' }} </b>
                                        </div>
                                        <div>
                                            <span class="text-success">{{item.bookingDetails.cabinClass[conIdx]}}</span>
                                            <p v-html="conItem"></p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-for="conItem,conIdx in item.bookingDetails.p2pConditions" :key="`p2p-con-${conIdx}`">
                                        <!-- <div class="p-2 border bg-light">
                                            <b>{{ conIdx == 0 ? 'Outbound' : 'Inbound' }} </b>
                                        </div> -->
                                        <div>
                                            <span class="text-success">{{item.bookingDetails.cabinClass[conIdx]}}</span>
                                            <p v-html="conItem"></p>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                        
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="border p-3 d-flex justify-content-end bg-soft-primary">
                        <div class="px-4" v-for="taxItem, taxIndex in bookingTotalTaxes" :key="`booking-tax-${taxIndex}`">
                            <small>{{taxItem.name}} <span class="text-danger">{{taxItem.sellingCurrencyCode + ' '+ taxItem.sellingAmount}}</span></small>
                        </div>
                        <div class="px-4 d-flex justify-content-end">
                            <div class="px-1">Total after commission</div>
                            <div class="px-1"><span class="text-danger"><strong>{{sellingCurrencyCode}}</strong></span></div>
                            <div class="px-1"><span class="text-danger"><strong>{{bookingTotalPrice}}</strong></span></div>  
                        </div> 
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="providerBookingStatus === 'PREBOOKED'">
                <b-col>
                    <div class="mt-2 d-flex justify-content-end">
                        <b-button
                        @click="toOpenIssueTicketConfirmModal" 
                        variant="success"
						v-permission="{permission: 'agencyportal.rail.ticketing', effect: 'hidden'}"
                        >
                        Check Out
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        </b-overlay>
        <b-alert
        :show="alertSettings.dismissCountDown"
        class="position-fixed fixed-top m-0 rounded-0 text-center"
        style="z-index: 2000;"
        :variant="alertSettings.alertVariant"
        dismissible
        @dismiss-count-down="countDownChanged"
        >
<!--            <div v-html="alertSettings.title"></div>-->
            <div v-html="alertSettings.alertMsg"></div>
        </b-alert>

        <b-modal id="issue-ticket-confirm-modal" 
        v-model="isIssueTicketConfirmModalVisible"
        centered hide-footer title="Issue ticket application">
            <!-- <div class="my-1">
                Total Amount: 
                <span class="text-danger">
                    <b>
                        <span class="mr-1">
                            {{bookedPriceForPass.sellingCurrencyCode}}
                        </span>
                        {{(bookedPriceForPass.sellingTotalAmount + bookedPriceForP2P.sellingTotalAmount).toFixed(2)}}
                    </b>
                </span>
            </div> -->
            <div class="my-2 d-flex">
                <div>
                    Booking ID: 
                    <span class="ml-1"><strong>#{{ checkOutBookingId }}</strong></span>
                </div>
                <div class="ml-4">
                    Booking Amount: 
                    <span class="ml-1 text-danger"><strong>{{ sellingCurrencyCode }}</strong></span>
                    <span class="ml-1 text-danger"><strong>{{ checkOutPrice }}</strong></span>
                </div>
            </div>
            <div class="my-4">
                <p>
                    <input v-model="isIssueTicketAgreed" type="checkbox" class="mr-2">
                    I agree to make payment and issue ticket by clicking "Issue Ticket" button
                </p>
            </div>
            <div class="d-flex justify-content-between">
                <b-button
                @click="hideIssueTicketModal" 
                variant="secondary"
                >
                Cancel
                </b-button>

                <b-button
                @click="checkOut" 
                variant="primary"
                :disabled="!isIssueTicketAgreed"
                >
                Issue Ticket
                </b-button>
            </div>
        </b-modal>
        
        <b-modal id="booking-refund-modal" v-if="isRefunding"
        v-model="isRefundModalVisible"
        centered hide-footer title="Refund application">
            <div class="my-1">
                Booking No.: <b>{{ selectedBooking.bookingId }}</b>    
                <br/>Record locator: <b>{{ refundReservation.bookingDetails.pnr }}</b>   
            </div>
            <div class="d-flex my-3">
                <div>
                    Booking total:
                    <br/>Penalty and fees:
                    <br/><span class="text-danger">Refundable amount:</span>
                </div>
                <div class="ml-4">
                    <span>{{refundReservation.bookingDetails.price.sellingCurrencyCode}}</span><span class="ml-1">{{ refundReservation.bookingDetails.price.sellingTotal }}</span>
                    
                    <br/><span>{{sellingRefundCurrency}}</span><span class="ml-1">-{{(Number(refundReservation.bookingDetails.price.sellingTotal) - sellingRefundAmount).toFixed(2)}}</span>
                    <br/><span class="text-danger">{{sellingRefundCurrency}}</span><span class="ml-1 text-danger">{{(sellingRefundAmount)}}</span>
                </div>
                
            </div>
            <div>
                <p>
                    <input v-model="isRefundAgreed" type="checkbox" class="mr-2">I agree the refundable amount shown above and understand the ticket(s) will be cancelled when Click "Confirm refund" button
                </p>
            </div>
            <div class="d-flex justify-content-between">
                <b-button
                @click="hideRefundConfirmModal" 
                variant="secondary"
                >
                Cancel
                </b-button>

                <b-button
                @click="refundConfirm" 
                variant="primary"
                :disabled="!isRefundAgreed || confirmRefundBtnDisabled"
                >
                Confirm refund
                </b-button>
            </div>
        </b-modal>

        <b-modal 
        v-model="isTicketingOptionHelperVisible"
        centered hide-footer title="Ticketing options">
            <ul>
                <li>
                    <b>PAH</b>(Print at home):Print tickets at home from <b>Tickets</b>.
                </li>
                <li>
                    <b>ETK</b>(Electronic ticket):Provider sends the tickets by e-mail.
                </li>
                <li>
                    <b>TICKETLESS</b>:Printed tickets are not required, but a valid photo ID and the PNR reference will be needed to board the train.
                </li>
                <li>
                    <b>TOD</b>(Ticket on departure):Travelers have to collect the tickets at the station using a valid payment card and the withdrawal reference.
                </li>
                <li>
                    <b>MPASS</b>(For pass items):Travelers have to download the Rail Planner app and follow the instructions using the Pass number.
                </li>
            </ul>
        </b-modal>
       
    </Layout>
    
</template>